<template>
  <div class="login">
        <h2 class="comm__title">LOGIN</h2>
        <div class="ipt__box">
            <input type="text" class="ipt" placeholder="ID" v-model="user.id">
        </div>
        <div class="ipt__box">
            <input type="password" class="ipt" placeholder="Password" v-model="user.password" @keyup.enter="login()">
        </div>
        <div class="ipt__btn">
            <a href="#" class="btn btn--confirm btn--large" v-on:click="login()">LOGIN</a>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default  {
  name: 'Home-login',
        data() {
            return {
                user : {
                    id : this.$route.params.id,
                    password : ''
                }
            }
        },
        methods : {
            login : function () {
                if (this.user.id == '') {alert('아이디를 입력해주세요.');return;}
                if (this.user.password == '') {alert('비밀번호를 입력해주세요.');return;}

                axios.post('http://52.79.81.221:3300/GetCertApi', {user:this.user})
            .then(res => {               

               if (res.data.Result == 0) {
                        //alert(res.data.Result);

                        if (this.user.id == 'taco')
                        {
                            if (this.user.password == '890iop')
                                this.$router.push('/TChart'); //로그인 성공시 처리
                            else
                                alert('password불일치');this.retrun;
                        }
                        else{
                            alert('아이디 불일치');
                            this.retrun;
                        }
                    } else {
                        alert(res.data.Result);
                    }
        })

                
                
            }
        },
        created () {
        }
    }
</script>
