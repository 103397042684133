<template>
 <body>
  <div class="list-group">
      <a v-for="rewardData in rewardData" href="#" class="list-group-item clearfix" v-bind:key="rewardData.Index" v-on:click="goJin(rewardData)">  
                
                <div class="background">
                  <h4>{{ rewardData.String1 }} </h4><h5>{{ rewardData.String2 }}</h5>
                  <img :src="require(`../image/gameicon/${rewardData.AppIcoImg}.png`)" style=";width:50px;height:50px;margin-top:-65px;">  
                    <!--{{ rewardData.Dev }}-->                   
                </div>
                
           <span class="pull-right">
                      
                        <div class="img-button" >
                          <img :src="require(`../image/${rewardData.PriceImg}.png`)" style=";width:30px;height:30px;margin-left:5px;margin-top:0px;margin-bottom:15px;">
                      <span class="glyphicon">  <h6>{{rewardData.Price}}</h6> </span>
                          
                        </div>
            </span>
          
                   
          <!--<p>
            {{ rewardData.GameTitle }}            
          </p>-->
          
      </a>
    </div>
   </body> 
</template>



<script>
import axios from "axios";
export default {
  name: 'HelloWorld',
   data: function () {
      return {
          rewardData: "",

          IDAD : this.$route.params.IDAD,
          TGIndex: this.$route.params.TGIndex
          
            
      }
  },
  created () {
      
  },
  beforeMount(){        
    //this.getGameListData(); //미리 부르면 TGIndex파라미터를 못받는다
  },
  methods: {
    goJin:  function (item) {
      //this.$router.push('/jin');
      this.$router.push({ name: "jin", 
      params: { 
                //Dev: JSON.stringify(item.Dev),
                Dev: item.Dev,
                AppBanImg:item.AppBanImg,
                PriceImg:item.PriceImg, 
                Price:item.Price,
                String1:item.String1,
                String2:item.String2,
                StringDetail:item.StringDetail,
                Url:item.Url,
                Index:item.Index,
                AppIcoImg: item.AppIcoImg,

                IDAD: this.IDAD,
                TGIndex: this.TGIndex
                
                } });
      
    },
    getGameListData: function (TGIndex) {
         axios.post('http://52.79.81.221:3300/GetOfferData', {TGIndex})
            .then(res => {
               this.rewardData =res.data;
               console.log(new Date());
               //alert(new Date()); 
        })
     }
  },
   computed: {
    
  },
  mounted() {

    this.getGameListData(this.TGIndex);
    //setInterval(this.getGameListData, 1000 * 60 * 60 * 1); //15시간마다 새로고침
    //setInterval(this.getGameListData, 10000); //10초마다 새로고침 
    var self = this;

    var id = setInterval(function () {
        if (new Date().getHours() >=22 && new Date().getHours() < 23){//특정시간대에 오퍼리스트 호출 하루에 한번
            console.log('   ',new Date().getMinutes());
            self.getGameListData();//인덱스,소팅순위(Index, SortLoc)를 mongo에 저장
            //clearInterval(id);
        } else{
            console.log('    ')
        }
    },1000 * 60 * 60, id)
  

  }
  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.background{
  
  overflow: hidden;

margin-top:-10px;
margin-bottom:-10px;
margin-left:-10px;
margin-right:-15px;
padding-top: 0px;
 

  background-image: url("../image/gameicon/Slot_Normal.png");
  background-color: rgb(255, 250, 244);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-button {
  
        margin-top:-45px;
        
        margin-bottom:-10px;
        background: url( "../image/gameicon/Btn_OrangeNormal.png") no-repeat;
        border: none;
        width: 82px;
        height: 31;
        /*max-width: 200px;*/
        cursor: pointer;
        /*background-size:contain;*/
        
      }
h6 { /*price*/
  /*margin: 5px 10px 0 10px;*/
  
  font-size: 15px;
  font-weight: bold;
  color: rgb(255,255,255);
  /*text-align: right;*/
}

h4 { /*설치하세요*/
  
  margin: 10px 55px 0;
  font-size: 14px;
  font-weight: bold;
  max-width: 52%;
  color: rgb(70,32,48);
  /*max-width: 230px;*/
  /*text-align: center;*/
}
h5 { /*보상*/
  margin: 10px 55px 0;
  max-width: 45%;
  color: rgb(181,160,169);
  /*text-align: center;*/
}

body { -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  }
</style>
