<template>
  <div>

  <input type="date" v-model="startDate">
  <input type="date" v-model="endDate">

  <button  @click="Apply">Apply</button>

  <select @change="GetListData($event)" v-model="selected">

 <option  v-for= "rewardData in rewardData" v-bind:key="rewardData.Index" :value ="rewardData.Index">
    {{ rewardData.Dev }} 
</option>

</select>
<!--<span>Selected: {{ selected }}</span>-->
 
<table>
    <h2></h2>
      <tr>
        <th>소팅순위</th>
        <th>노출</th>
        <th>미션완료수</th>       
      </tr>
      <tr>        
        <td><span v-html="selectdata.SortLoc"></span></td>
        <td><span v-html="selectdata.MissionClickCnt"></span></td>
        <td><span v-html="selectdata.MissionEndCnt"></span></td>        
      </tr>
    </table>

  
<h5>
    <canvas
      ref="barChart"
      width = "800" height="300">
     <!-- style="width:80vw; height:30vh">-->
      
    </canvas>
</h5>
  </div>
</template>

<script>
import axios from "axios";
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
let chart = undefined;


function stringToDate(str) {
  const [y, m, d] = str.split('-')
  return new Date(+y, m - 1, +d)
}

function dateToString(date) {
  return (
    
    date.getFullYear() +
    '-' +
    pad(date.getDate() < 7 ? date.getMonth() : date.getMonth() + 1) +
    '-' +
    pad(date.getDate() < 7 ? date.getDate() + 20 : date.getDate() - 7)
  )
}
function dateToString1(date) {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate())
  )
}

function pad(n, s = String(n)) {
  return s.length < 2 ? `0${s}` : s
}

function getDateRangeData(param1, param2){  //param1은 시작일, param2는 종료일이다.
	var res_day = [];
    var ss_day = new Date(param1);
    var ee_day = new Date(param2);
    while(ss_day.getTime() <= ee_day.getTime()){
        var _mon_ = (ss_day.getMonth()+1);
        _mon_ = _mon_ < 10 ? '0'+_mon_ : _mon_;
        var _day_ = ss_day.getDate();
        _day_ = _day_ < 10 ? '0'+_day_ : _day_;
        //res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' +  _day_);//년월일 표시
        res_day.push(_mon_ + '/' +  _day_);//월일만 표시
        ss_day.setDate(ss_day.getDate() + 1);
        }
        return res_day;
        }


var startDate = dateToString(new Date());
var endDate = dateToString1(new Date());



//var xValues = getDateRangeData('2022-04-14', '2022-04-21');
var xValues = getDateRangeData(startDate, endDate);



export default {

  data:() => ({

  startDate: dateToString(new Date()),
  endDate: dateToString1(new Date()),


  rewardData: "",
  selected: '',
   selectdata:"",

    //type: 'line',
    data: {              
      labels: xValues,//지정날짜를  배열로 
      datasets: [{
        type: 'line',
        label: '소팅순위',
        data: [ 1, 3, 5, 2, 3, 1 ],        
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 2,
        //order: 1
      },       
      {
        type: 'bar',
        label: '노출',
        data: [ 30, 40, 50, 60, 70, 80 ],   
        backgroundColor: 'rgb(54,162,235, 0.2)',     
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
        //order: 2,
        grouped: true
      },       
      {
        type: 'bar',
        label: '미션완료수',
        data: [ 10, 3, 5, 7, 9, 10 ],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',        
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
        //order: 3,
        grouped: true
      }

      
      ]
    },
    options: {
      responsive: false,
      scales: {//누적그래프
        x: {
          stacked: true,
          //display: false,
          beginAtZero: true
        }
      },
      indexAxis: 'x',
    }
    
  }),

  beforeMount(){           
    this.getGameListData();
    this.selected = '';
  },

  mounted(){
    
    this.createChart();
    //this.getGameListData();
  },
  methods:{
    createChart(){

if (chart !== undefined){
        chart.destroy()
      }

      this.data.labels = xValues;
      chart = new Chart(this.$refs.barChart, {       
        
        data:this.data,
        options:this.options
      })

      chart.data.datasets[0].data[0] = '3'
      chart.data.datasets[1].data[0] = '40'
      chart.data.datasets[2].data[0] = '3'
      chart.data.datasets[0].data[6] = '5'
      chart.data.datasets[1].data[6] = '50'
      chart.data.datasets[2].data[6] = '5'
      chart.data.datasets[0].data[7] = '7'
      chart.data.datasets[1].data[7] = '60'
      chart.data.datasets[2].data[7] = '7'
      chart.update()

    },

    Apply() {

        if (!this.CanApply)
        {
            alert(`end date must be after start date.`)      
        }
        else {

            xValues = getDateRangeData(this.startDate, this.endDate);
            this.createChart()

            alert(`FROM ${this.startDate} TO ${this.endDate}`)
           
        }

      
    },

    getGameListData: function () {
         axios.post('http://52.79.81.221:3300/GetOfferData', {})
            .then(res => {
               this.rewardData =res.data;

               //alert(JSON.stringify(this.rewardData));  
        })
     },
     GetListData(event) {
         //console.log(event.target.value);
           
           
         var Index = event.target.value;
         axios.post('http://52.79.81.221:3300/GetSelectData', { Index })//Index에 맞는 sortloc, missionclickcnt, missionend를 가져온다.
            .then(res => {
              //alert(res.data.Result);   
               this.selectdata = res.data;           
              //console.log(this.selectdata.MissionEndCnt);
        })
     },

  },



  computed: {   
    CanApply() {
      return (        
        stringToDate(this.endDate) > stringToDate(this.startDate)
      )
    }
  },

}
</script>

<style scoped>
li {
  
  margin: 0 10px;
  
}

h5 {
  margin: 200px 0 0;  
}

h3 {
  margin: 50px 0 0;
  text-align: center;
}
h2 {
  margin: 30px 0 0;
  text-align: center;
}


td, th {
   
  padding: 10px;
  border: 1px solid #ccc;
}
body {
  padding: 1rem;
}

</style>