import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import GameList from "@/pages/gameList.vue";
import Jin from "@/pages/jin.vue";
import TChart from "@/pages/TChart.vue";
import RewardList from "@/pages/rewardList.vue";

const routes = [
  {
    path: "/home/:id",
    name: "Home",
    component: Home,
  },
  {
    path: "/gameList/:IDAD/:TGIndex",
    name: "gameList",
    component: GameList,
  },
  {
    path: "/jin",
    name: "jin",
    component: Jin,
  },
  {
    path: "/TChart",
    name: "TChart",
    component: TChart,
  },
  {
    path: "/rewardList/:IDAD/:TGIndex/:ReType",
    name: "rewardList",
    component: RewardList,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;