<template>
<body>
  <div class="background">

    <img :src="require(`../image/gameicon/${AppBanImg}.png`)" style="display: block; margin: 0px auto;width:400px;height:276px;"/>
        <a v-bind:href="Url" target="_blank" rel="noopener noreferrer" v-on:click="SetMissionClickData(IDAD, TGIndex, Index, Price, String1, AppIcoImg, PriceImg)">
              <div class="img-button" >
      
      
                      <h1> <img :src="require(`../image/${PriceImg}.png`)" style="width:50px;height:50px;margin-left:-150px;margin-top:5px;margin-bottom:15px;"> </h1>
                      
                      
                      <span class= "center-block">  
                        <h6>{{Price}}받기</h6> 
                      </span>

      
                          
              </div>

        </a>
    
        
    <ul>
    <!--<li><img :src="require(`../image/${PriceImg}.png`)" style="; margin: 10px auto;;width:100px;height:50px;"/></li>
    <li><a v-bind:href="Url" target="_blank" rel="noopener" v-on:click="SetMissionClickData(IDAD, TGIndex, Index, Price, String1, AppIcoImg, PriceImg)"><h3>{{ Price }}루비 받기</h3></a></li>
    -->
    
      <h3>{{ String1 }}</h3> 
      <h3>{{ String2 }}</h3>
      <h3>{{ StringDetail }}</h3>



     <h1> <img :src="require(`../image/gameicon/Btn_PurpleGray.png`)" style="width:150px;height:50px;margin-left:150px;margin-top:5px;margin-bottom:15px;" @click="onClickQuestion()"> </h1>
        <span >  
            <h2><p @click="onClickQuestion()">문의 하기</p></h2>             
        </span>              
                      



<!--
		
      <h2><button @click="onClickQuestion()">문의 하기</button></h2>
-->
    
    </ul>
   
                      

  </div>
  </body>
</template>

<script>

import axios from "axios";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },



  data() {
    return {    
      
    //Post로 받았을 경우
     Dev: this.$route.params.Dev,
     AppBanImg:this.$route.params.AppBanImg,
     PriceImg:this.$route.params.PriceImg, 
     Price:this.$route.params.Price, 
     String1:this.$route.params.String1,
     String2:this.$route.params.String2,
     StringDetail:this.$route.params.StringDetail,
     Url:this.$route.params.Url,
     Index:this.$route.params.Index,
     AppIcoImg:this.$route.params.AppIcoImg,

     IDAD: this.$route.params.IDAD,
     TGIndex: this.$route.params.TGIndex
    
    };
  },

  created() {
  
  //alert(this.$route.params.Url);
 
  },

methods: {
SetMissionClickData: function (IDAD, TGIndex, Index, Price, String1, AppIcoImg, PriceImg) {
         axios.post('http://52.79.81.221:3300/SetMissionClickData', {IDAD, TGIndex, Index, Price, String1, AppIcoImg, PriceImg})
            .then(res => {
              //alert(res.data.Result);              
              console.log(res.data.Result);
        })
     },
    onClickQuestion: function () {
         window.open(this.Url, "_blank", "noopener noreferrer");
     }
}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h5 {
  margin: 10px 0 0;
  text-align: center;
}

h3 {
  margin: 50px 0 0;
  text-align: center;
}

h2 {

  margin-top: -50px;
margin-left: 150px;
  /*display:block;*/
  font-size: 22px;
  font-weight: bold;
  color: rgb(255,255,255);
  text-align: center;

  
}

h1 {
  margin: 70px 0 0;
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  
}
a {
  color: #42b983;
  
}

.background{
  height: 100vh;
  /*overflow: hidden;/*스크롤 숨김*/
  
  background-color: rgb(255, 250, 244);
  
  
}
.img-button {
  
       margin-top: -70px;
        background: url( "../image/gameicon/Btn_BigOrangeNormal.png") ;
        
        /*background-repeat: no-repeat;*/
        cursor: pointer;
        background-size:cover;
        
      }

h6 { /*price*/
  /*margin: 5px 10px 0 10px;*/
  
  /*position: relative; top: 70%; left: 50%; transform: translate(-50%, -50%); */
  
  margin-top: -47px;

  /*display:block;*/
  font-size: 25px;
  font-weight: bold;
  color: rgb(255,255,255);
  text-align: center;
}



body { -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  }
</style>
